<!-- eslint-disable import/no-cycle -->
<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Planificateur des tâches"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="listePlanificationEntretiens"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${statusTacheEntretienVariant(data.item.status)}`"
              >
                {{ data.item.status }}

              </b-badge>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.piece_jointe"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Prévisualiser la pièce jointe'"
              v-b-modal.modal-preview
              variant="gradient-primary"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Prévisualiser la pièce jointe'"
              variant="gradient-primary"
              size="sm"
              disabled
              class="btn-icon rounded-circle ml-1"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>

            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier une contribution'"
              v-b-modal.modal-manage-common-erea
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button> -->
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="listePlanificationEntretiens.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    <b-modal
      id="modal-manage-common-erea"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      :modal-class="form.id ? 'modal-warning' : 'modal-success'"
      :title="form.id ? 'Modification de tâche' : 'Planifier une tâche'"
      :busy="partieCommuneProcessCloseModal"
      :ok-title="form.id ? 'Modifier' : 'Ajouter'"
      cancel-title="Fermer"
      cancel-variant="danger"
      :ok-variant="form.id ? 'warning' : 'success'"
      size="lg"
      @hidden="resetForm"
      @ok="submitForm"
    >
      <validation-observer ref="coproprieteFormRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Partie commune"
                label-for="partie_commune_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="partie commune"
                  rules="required"
                >
                  <v-select
                    id="partie_commune_id"
                    v-model="form.partie_commune_id"
                    label="name"
                    :state="errors.length > 0 ? false : null"
                    :options="listePartieCommune"
                    :reduce="(listePartieCommune) => listePartieCommune.id"
                    placeholder="Séléctionner une partie commune"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tâche d'entretien"
                label-for="tache_entretien_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tâche d'entretien"
                  rules="required"
                >
                  <v-select
                    id="tache_entretien_id"
                    v-model="form.tache_entretien_id"
                    label="libelle"
                    :state="errors.length > 0 ? false : null"
                    :options="listeTacheEntretiens"
                    :reduce="(listeTacheEntretiens) => listeTacheEntretiens.id"
                    placeholder="Séléctionner une tâche d'entretien"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col md="6">
              <b-form-group
                label="Appartements concernés"
                label-for="partie_commune_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="appartements concernés"
                >
                  <v-select
                    id="appartement_ids"
                    v-model="form.appartement_ids"
                    label="libelle"
                    multiple
                    :state="errors.length > 0 ? false : null"
                    :options="appartements"
                    :reduce="(appartements) => appartements.id"
                    placeholder="Séléctionner les appartements concernés"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Villas concernées"
                label-for="partie_commune_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="villas concernées"
                >
                  <v-select
                    id="villa_ids"
                    v-model="form.villa_ids"
                    label="name"
                    multiple
                    :state="errors.length > 0 ? false : null"
                    :options="villas"
                    :reduce="(villas) => villas.id"
                    placeholder="Séléctionner les villas concernées"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de début"
                label-for="date_debut"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de début"
                  rules="required"
                >
                  <flat-pickr
                    id="date_debut"
                    v-model="form.date_debut"
                    :config="config"
                    class="form-control mb-1"
                    placeholder="Date de début"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de fin"
                label-for="date_fin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de fin"
                  rules="required"
                >
                  <flat-pickr
                    id="date_fin"
                    v-model="form.date_fin"
                    :config="config"
                    class="form-control mb-1"
                    placeholder="Date de fin"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label-for="recurrence"
                label="La récurrence "
              >
                <validation-provider
                  #default="{ errors }"
                  name="récurrence"
                  rules="required"
                >

                  <v-select
                    id="recurrence"
                    v-model="form.recurrence"
                    label="text"
                    :state="errors.length > 0 ? false : null"
                    :options="periodiciteListe"
                    :reduce="(periodiciteListe) => periodiciteListe.text"
                    placeholder="Sélectionner la récurrence"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-for="prestataire_de_service"
                label="La société de prestation "
              >
                <validation-provider
                  #default="{ errors }"
                  name="société de prestation"
                  rules="required"
                >

                  <v-select
                    id="prestataire_de_service"
                    v-model="form.prestataire_de_service"
                    label="company_name"
                    :state="errors.length > 0 ? false : null"
                    :options="societes"
                    :reduce="(societes) => societes.id"
                    placeholder="Sélectionner la société de prestation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-for="priorite"
                label="La priorité "
              >
                <validation-provider
                  #default="{ errors }"
                  name="priorité"
                  rules="required"
                >

                  <v-select
                    id="priorite"
                    v-model="form.priorite"
                    label="text"
                    :state="errors.length > 0 ? false : null"
                    :options="prioriteListe"
                    :reduce="(prioriteListe) => prioriteListe.text"
                    placeholder="Sélectionner la priorité"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de rappel"
                label-for="date_rappel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de rappel"
                  rules="required"
                >
                  <flat-pickr
                    id="date_rappel"
                    v-model="form.date_rappel"
                    :config="config"
                    class="form-control mb-1"
                    placeholder="Date de rappel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Pièce jointe"
                label-for="piece_jointe"
              >
                <validation-provider
                  #default="{ errors }"
                  name="pièce jointe"
                >
                  <b-form-file
                    v-model="form.piece_jointe"
                    placeholder="Selectionner une pièce jointe..."
                    drop-placeholder="Votre fichier ici..."
                    accept=".pdf"
                    @change="handlePdfSelected"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description "
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-preview"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      modal-class="modal-success"
      title="La pièce jointe"
      ok-only
      ok-title="Fermer"
      ok-variant="danger"
      size="lg"
    >
      <b-row>
        <b-col
          md="12"
        >
          <embed
            :src="`${SITE_URL}${pdfFile}`"
            type="application/pdf"
            width="100%"
            height="600px"
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal,
  BFormTextarea, BFormFile, BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
// eslint-disable-next-line no-unused-vars, import/no-cycle
import useOperations from '@/composables/operationService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/composables/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import useSocietes from '@/composables/societeService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    vSelect,
    flatPickr,
    BFormFile,
    BBadge,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

    const {

      appartements,
      villas,
      getVillasByCopropriete,
      getAppartementsByCopropriete,

    // eslint-disable-next-line no-undef
    } = useProprietes()
    const {
      getAllSocietesByCategorieSocieteId, societes,

    // eslint-disable-next-line no-undef
    } = useSocietes()
    const {
      imageUrl, imageFile, fileName, handlePdfSelected, uploadPdfFile,
    } = useImageUpload()
    const { SITE_URL } = $themeConfig.app

    const {
      handleTacheEntretien,
      getAllPartiesCommunes,
      listePartieCommune,
      loader,
      partieCommuneProcessCloseModal,
      getPlanificationEntretienNotEnAttenteByCoproprieteId,
      listePlanificationEntretiens,
      getAllTacheEntretienByCoproprieteId,
      listeTacheEntretiens,
      handleUpdateTacheEntretien,
    } = useOperations()
    const user = JSON.parse(store.state.authStore.user)

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'date_debut', label: 'Date début', sortable: true },
      { key: 'date_fin', label: 'Date fin', sortable: true },
      { key: 'date_rappel', label: 'Date rappel', sortable: true },
      { key: 'recurrence', label: 'Recurrence', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'priorite', label: 'Priorité', sortable: true },
      { key: 'status', label: 'Statut', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      partie_commune_id: null,
      tache_entretien_id: null,
      copropriete_id: null,
      date_fin: null,
      date_debut: null,
      recurrence: null,
      piece_jointe: null,
      date_rappel: null,
      prestataire_de_service: null,
      libelle: '',
      description: '',
      priorite: '',
      appartement_ids: [],
      villa_ids: [],
    })

    const upload = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await uploadPdfFile(data)
        // eslint-disable-next-line no-undef
        form.piece_jointe = fileName.value
        form.temporay_url = imageUrl.value

        // if (oldImage.value !== null) await deleteFile(oldImage.value, 'uploads')
      }
    }
    const pdfFile = ref('')

    // Reset Form
    const resetForm = () => {
      form.id = null
      form.partie_commune_id = null
      form.tache_entretien_id = null
      form.libelle = ''
      form.description = ''
      form.appartement_ids = []
      form.villa_ids = []
    }

    const communes = ref([])
    const myModal = ref('')

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    onMounted(async () => {
      await getAllPartiesCommunes(user.current_coownership)
      await getPlanificationEntretienNotEnAttenteByCoproprieteId(user.current_coownership)
      await getAllTacheEntretienByCoproprieteId(user.current_coownership)
      await getAppartementsByCopropriete(user.current_coownership)
      await getVillasByCopropriete(user.current_coownership)
      await getAllSocietesByCategorieSocieteId(2)

      // Set the initial number of items
      totalRows.value = listePlanificationEntretiens.value.length
    })

    const coproprieteFormRules = ref('')

    // Récupérer un enregistrement
    const getItem = async item => {
      form.id = item.id
      form.libelle = item.libelle
      form.partie_commune_id = item.partie_commune_id
      form.tache_entretien_id = item.tache_entretien_id
      form.copropriete_id = item.copropriete_id
      form.date_fin = item.date_fin
      form.date_debut = item.date_debut
      form.recurrence = item.recurrence
      form.date_rappel = item.date_rappel
      form.appartement_ids = item.appartement_ids
      form.villa_ids = item.villa_ids
      form.priorite = item.priorite

      pdfFile.value = item.piece_jointe_url
    }
    const statusTacheEntretienVariant = status => {
      if (status === 'En cours') return 'primary'
      if (status === 'En attente') return 'success'
      if (status === 'Planifiée') return 'success'
      if (status === 'Non planifiée') return 'info'
      if (status === 'Reportée') return 'info'
      if (status === 'En retard') return 'danger'
      if (status === 'Annulée') return 'danger'
      if (status === 'Terminée') return 'success'
      return 'warning'
    }

    const periodiciteListe = ref([
      { value: 'select_value', text: 'Sélectionner' },
      { value: 'Journalier', text: 'Journalier' },
      { value: 'Hebdomadaire', text: 'Hebdomadaire' },
      { value: 'Mensuel', text: 'Mensuel' },
      { value: 'Bimestriel', text: 'Bimestriel' },
      { value: 'Trimestriel', text: 'Trimestriel' },
      { value: 'Semestriel', text: 'Semestriel' },
      { value: 'Annuel', text: 'Annuel' },
    ])

    const prioriteListe = ref([
      { value: 'Faible', text: 'Faible' },
      { value: 'Médium', text: 'Médium' },
      { value: 'Haute', text: 'Haute' },
    ])
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y H:i:s',
      altInput: true,
      enableTime: true,
      dateFormat: 'Y-m-d H:i:s',
      locale: French, // locale for this instance only
    }

    const handleSubmitModal = async () => {
      form.copropriete_id = user.current_coownership

      coproprieteFormRules.value.validate().then(async success => {
        if (success) {
          await upload()

          if (form.id) {
            await handleUpdateTacheEntretien({ ...form })
          } else {
            await handleTacheEntretien({ ...form })
          }

          if (partieCommuneProcessCloseModal.value) myModal.value.toggle('#toggle-btn')

          await getPlanificationEntretienNotEnAttenteByCoproprieteId(user.current_coownership)
        }
      })
    }

    //  Fonction de soumission du modal
    const submitForm = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmitModal()
    }

    return {

      sortOptions,
      communes,
      loader,
      partieCommuneProcessCloseModal,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      myModal,
      filterOn,
      fields,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      avatarText,
      coproprieteFormRules,
      handleSubmitModal,
      submitForm,
      getItem,

      listePartieCommune,
      listeTacheEntretiens,
      listePlanificationEntretiens,
      periodiciteListe,
      config,
      appartements,
      villas,
      handlePdfSelected,
      SITE_URL,
      pdfFile,
      prioriteListe,
      societes,
      statusTacheEntretienVariant,

    }
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
